import React, { Component, ChangeEvent, FormEvent } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { CardElement, injectStripe, StripeProvider } from "react-stripe-elements";

import LoaderButton from "./LoaderButton";

import styles from "./BillingForm.module.scss";

interface BillingFormProps {
  loading: boolean;
  onSubmit: Function;
  stripe?: stripe.Stripe | any;
}

interface BillingFormState {
  name: string;
  storage: string;
  isProcessing: boolean;
  isCardComplete: boolean;
}

class BillingForm extends Component<BillingFormProps, BillingFormState> {
  constructor(props: BillingFormProps) {
    super(props);

    this.state = {
      name: "",
      storage: "",
      isProcessing: false,
      isCardComplete: false
    };
  }

  validateForm(): boolean {
    return (
      this.state.name !== "" &&
      this.state.storage !== "" &&
      this.state.isCardComplete
    );
  }

  handleFieldChange = (event: ChangeEvent<FormControl & HTMLInputElement>): void =>
    this.setState({ ...this.state, [event.target.id]: event.target.value });

  handleCardFieldChange = (event: stripe.elements.ElementChangeResponse): void =>
    this.setState({ isCardComplete: event.complete });

  handleSubmitClick = async (event: FormEvent<FormEvent & HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const { name } = this.state;

    this.setState({ isProcessing: true });

    const { token, error } = await this.props.stripe.createToken({ name });

    this.setState({ isProcessing: false });

    this.props.onSubmit(this.state.storage, token, error);
  };

  render() {
    const loading = this.state.isProcessing || this.props.loading;

    return (
      <form
        className={styles.BillingForm}
        onSubmit={(e: FormEvent<FormEvent & HTMLFormElement>) =>
          this.handleSubmitClick(e)
        }
      >
        <FormGroup bsSize="large" controlId="storage">
          <ControlLabel>Storage</ControlLabel>
          <FormControl
            min="0"
            type="number"
            value={this.state.storage}
            onChange={this.handleFieldChange}
            placeholder="Number of notes to store"
          />
        </FormGroup>
        <hr />
        <FormGroup bsSize="large" controlId="name">
          <ControlLabel>Cardholder's name</ControlLabel>
          <FormControl
            type="text"
            value={this.state.name}
            onChange={this.handleFieldChange}
            placeholder="Name on the card"
          />
        </FormGroup>
        <ControlLabel>Credit Card Info</ControlLabel>
        <CardElement
          className="card-field"
          onChange={(e: stripe.elements.ElementChangeResponse) => {
            return this.handleCardFieldChange(e);
          }}
          style={{
            base: { fontSize: "18px", fontFamily: '"Open Sans", sans-serif' }
          }}
        />
        <LoaderButton
          block
          bsSize="large"
          type="submit"
          text="Purchase"
          isLoading={loading}
          loadingText="Purchasing..."
          disabled={!this.validateForm()}
        />
      </form>
    );
  }
}

export default injectStripe(BillingForm);