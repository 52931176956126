import React from "react";
import { Button, Glyphicon, ButtonProps } from "react-bootstrap";
import styles from "./LoaderButton.module.scss";

interface LoaderButtonProps extends ButtonProps {
  isLoading: boolean;
  text: string;
  loadingText: string;
  className?: string;
  disabled?: boolean;
}

export default ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}: LoaderButtonProps): JSX.Element =>
  <Button
    className={`${styles.LoaderButton} ${className}`}
    disabled={disabled || isLoading}
    {...props}
    >
    {isLoading && <Glyphicon glyph="refresh" className={`${styles.spinning} ${styles.glyphicon}`} />}
    {!isLoading ? text : loadingText}
  </Button>;