import React from "react";
import { Route, Switch } from "react-router-dom";

import AppliedRoute from "./components/AppliedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";

import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import Signup from "./containers/Signup";
import NewItem from "./containers/NewItem";
import Item from "./containers/Item";
import Settings from "./containers/Settings";

import { RoutesEnum } from "./enums/RoutesEnum";
import { AppChildProps } from "./App";

interface RoutesProps {
  childProps: AppChildProps;
}

export default ({ childProps }: RoutesProps) => (
  <Switch>
    <AppliedRoute path={RoutesEnum.Home} exact component={Home} props={childProps} />
    <UnauthenticatedRoute path={RoutesEnum.Login} exact component={Login} props={childProps} />
    <UnauthenticatedRoute path={RoutesEnum.Signup} exact component={Signup} props={childProps} />
    <AuthenticatedRoute path={RoutesEnum.ItemsNew} exact component={NewItem} props={childProps} />
    <AuthenticatedRoute path={`${RoutesEnum.Item}/:id`} exact component={Item} props={childProps} />
    <AuthenticatedRoute path={RoutesEnum.Settings} exact component={Settings} props={childProps} />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);
