import { Storage } from "aws-amplify";

interface storedItem {
  key: string;
}

export async function s3Upload(file: File) {
  const fileName = `${Date.now()}-${file.name}`;

  const stored = await Storage.vault.put(fileName, file, { contentType: file.type }) as storedItem;

  return stored.key;
}
