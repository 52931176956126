const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "packzero-app-api-dev-attachmentsbucket-6mrjbdfjsjw1"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.packzero.app/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_GNjhbijdP",
    APP_CLIENT_ID: "7ki2en4ekkvm15uk625bco3igr",
    IDENTITY_POOL_ID: "us-east-1:92696b1f-22c2-4060-aac4-2c4e295ae435"
  },
  STRIPE_KEY: "pk_test_KMC67T9kNW3jlNkM0z1ApY2y"
};

const prod = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "packzero-app-api-prod-attachmentsbucket-mjck5ughhd93"
  },
  apiGateway: {
    REGION: "us-east-1",
    URL: "https://api.packzero.app/prod"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_MuOJk8aY0",
    APP_CLIENT_ID: "4icp8kepvl9ugtm1d9792pa1pd",
    IDENTITY_POOL_ID: "us-east-1:859b18ac-a745-4535-a2cb-749694c2af4c"
  },
  STRIPE_KEY: "pk_test_KMC67T9kNW3jlNkM0z1ApY2y"
};

// Default to dev if not set
const config = process.env.REACT_APP_STAGE === 'prod'
  ? prod
  : dev;

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config
};