import React, { Component } from "react";
import { PageHeader, ListGroup, ListGroupItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { API } from "aws-amplify";

import styles from "./Home.module.scss";

import { Item } from "../types/Item";

import { ApisEnum, ApiPathsEnum } from "../enums/ApiEnum";
import { RoutesEnum } from "../enums/RoutesEnum";
import { Link } from "react-router-dom";
import { RouterProps } from "react-router";
import { AppChildProps } from "../App";


interface HomeProps extends RouterProps, AppChildProps {}

interface HomeState {
  isLoading: boolean;
  items: Item[];
  packWeight: Number;
}

export default class Home extends Component<HomeProps, HomeState> {
  constructor(props: HomeProps) {
    super(props);

    this.state = {
      isLoading: true,
      items: [],
      packWeight: 0
    };
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      return;
    }

    try {
      const items = await this.items();
      this.setState({ items });
      const packWeight = this.calculatePackWeight();
      this.setState({ packWeight });
    } catch (e) {
      alert(e.message);
    }

    this.setState({ isLoading: false });
  }

  calculatePackWeight(): number {
    const items = this.state.items;
    let packWeight = 0;

    for (let item of items) {
      if (item.weight !== undefined) {
        packWeight += +item.weight;
      }
    }

    return packWeight;
  }

  items(): Promise<Item[]> {
    return API.get(ApisEnum.Items, ApiPathsEnum.Items, {});
  }

  renderItemsList(items: Item[]): JSX.Element[] {
    return items.map((value, i): JSX.Element => {
      const item = value;
      return (
        <LinkContainer
          key={item.itemId}
          to={`${RoutesEnum.Item}/${item.itemId}`}
        >
          <ListGroupItem header={item.content.trim().split("/n")[0]}>
            {`Created: ${new Date(item.createdAt!).toLocaleString()}`}<br/>
            {item.weight && `Weight: ${item.weight}`}
          </ListGroupItem>
        </LinkContainer>
      );
    });
  }

  renderLander(): JSX.Element {
    return (
      <div className={styles.lander}>
        <h1>Pack Zero</h1>
        <p>Create packlists, and minimize weight for your adventures.</p>
        <div>
          <Link to={RoutesEnum.Login} className="btn btn-info btn-lg">
            Login
          </Link>
          <Link to={RoutesEnum.Signup} className="btn btn-success btn-lg">
            Signup
          </Link>
        </div>
      </div>
    );
  }

  renderItems(): JSX.Element {
    return (
      <div className={styles.items}>
        <PageHeader>Your Items, weight: {this.state.packWeight}</PageHeader>
        {!this.state.isLoading &&
          <ListGroup>
            <LinkContainer key={"new"} to={RoutesEnum.ItemsNew}>
              <ListGroupItem>
                <h4>
                  <b>{"\uFF0B"}</b> Create a new item
                </h4>
              </ListGroupItem>
            </LinkContainer>
            {this.renderItemsList(this.state.items)}
          </ListGroup>
        }
      </div>
    );
  }

  render(): JSX.Element {
    return (
      <div className={styles.Home}>
        {this.props.isAuthenticated ? this.renderItems() : this.renderLander()}
      </div>
    );
  }
}
