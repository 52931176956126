import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { AppChildProps } from "../App";

export interface AppliedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  props: AppChildProps;
}

export default ({ component: C, props: cProps, ...rest }: AppliedRouteProps) => {
  return (
  <Route {...rest} render={props => <C {...props} {...cProps} />} />
)};
