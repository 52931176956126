import React, { Component } from "react";
import { FormControl, FormGroup, ControlLabel } from "react-bootstrap";
import { API, Storage } from "aws-amplify";

import { s3Upload } from "../libs/awsLib";

import LoaderButton from "../components/LoaderButton";

import { ApisEnum, ApiPathsEnum } from "../enums/ApiEnum";

import { Item as ItemInterface } from "../types/Item";

import config from "../config";

import styles from "./Item.module.scss";
import { RoutesEnum } from "../enums/RoutesEnum";
import { RouteComponentProps, match } from "react-router";
import { AppChildProps } from "../App";

interface ItemProps extends RouteComponentProps, AppChildProps {
  match: itemMatch;
}

interface ItemState {
  isDeleting: boolean;
  isLoading: boolean;
  item: ItemInterface | null,
  content: string,
  weight: number,
  attachmentURL: string | null,
}

interface itemMatch extends match {
  params: itemMatchParams;
}

interface itemMatchParams {
  id?: string;
}

export default class Item extends Component<ItemProps, ItemState> {
  file: File | null;

  constructor(props: ItemProps) {
    super(props);

    this.file = null;

    this.state = {
      isDeleting: false,
      isLoading: false,
      item: null,
      content: "",
      weight: 0,
      attachmentURL: null
    };
  }

  async componentDidMount() {
    try {
      let attachmentURL: string | null = null;
      const item: ItemInterface = await this.getItem();
      const { content, attachment, weight } = item;

      if (attachment) {
        attachmentURL = (await Storage.vault.get(attachment)) as string;
      }

      this.setState({
        item,
        content,
        attachmentURL,
        weight
      });
    } catch (e) {
      alert(e);
    }
  }

  getItem(): Promise<ItemInterface> {
    return API.get(
      ApisEnum.Items,
      `${ApiPathsEnum.Items}/${this.props.match.params.id}`,
      {}
    );
  }

  validateForm(): boolean {
    return this.state.content.length > 0 && this.state.weight > 0;
  }

  formatFilename(str: string): string {
    return str.replace(/^\w+-/, "");
  }

  handleChange = (event: React.ChangeEvent<FormControl & HTMLInputElement>): void => {
    this.setState({ ...this.state, [event.target.id]: event.target.value });
  };

  handleFileChange = (
    event: React.ChangeEvent<FormControl & HTMLInputElement>
  ): void => {
    this.file = event.target.files!.length ? event.target.files![0] : null;
  };

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    let attachment: string | null = null;

    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(`Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE}`);
      return;
    }

    this.setState({ isLoading: true });

    try {
      if (this.file) {
        attachment = await s3Upload(this.file);
      }

      await this.saveItem({
        content: this.state.content,
        attachment: attachment || this.state.item!.attachment,
        weight: this.state.weight
      });

      this.props.history.push(RoutesEnum.Home);
    } catch (e) {
      alert(e);
      this.setState({ isLoading: false });
    }
  };

  deleteItem(): Promise<void> {
    return API.del(ApisEnum.Items, `${ApiPathsEnum.Items}/${this.props.match.params.id}`, {});
  }

  handleDelete = async (event: React.FormEvent<FormControl>): Promise<void> => {
    event.preventDefault();

    const confirmed = window.confirm(
      "Are you sure you want to delete this item?"
    );

    if (!confirmed) {
      return;
    }

    this.setState({ isDeleting: true });

    try {
      await this.deleteItem();
      this.props.history.push(RoutesEnum.Home);
    } catch (e) {
      alert(e);
      this.setState({ isDeleting: false });
    }
  };

  saveItem(item: ItemInterface): Promise<void> {
    return API.put(ApisEnum.Items, `${ApiPathsEnum.Items}/${this.props.match.params.id}`, {
      body: item
    });
  }

  render(): JSX.Element {
    return (
      <div className={styles.Item}>
        {this.state.item && (
          <form onSubmit={this.handleSubmit}>
            <FormGroup controlId="content">
              <FormControl
                onChange={(
                  e: React.ChangeEvent<FormControl & HTMLInputElement>
                ) => this.handleChange(e)}
                value={this.state.content}
                componentClass="textarea"
              />
            </FormGroup>
            <FormGroup controlId="weight">
              <FormControl
                onChange={(
                  e: React.ChangeEvent<FormControl & HTMLInputElement>
                ) => this.handleChange(e)}
                value={this.state.weight}
                componentClass="input"
              />
            </FormGroup>
            {this.state.item.attachment && (
              <FormGroup>
                <ControlLabel>Attachment</ControlLabel>
                <FormControl.Static>
                  <a
                    target="_blank"
                    rel="noopener noreference"
                    href={this.state.attachmentURL!}
                  >
                    {this.formatFilename(this.state.item.attachment)}
                  </a>
                </FormControl.Static>
              </FormGroup>
            )}
            <FormGroup controlId="file">
              {!this.state.item.attachment && (
                <ControlLabel>Attachment</ControlLabel>
              )}
              <FormControl
                onChange={(
                  e: React.ChangeEvent<FormControl & HTMLInputElement>
                ) => this.handleFileChange(e)}
                type="file"
              />
            </FormGroup>
            <LoaderButton
              block
              bsStyle="primary"
              bsSize="large"
              disabled={!this.validateForm()}
              type="submit"
              isLoading={this.state.isLoading}
              text="Save"
              loadingText="Saving..."
            />
            <LoaderButton
              block
              bsStyle="danger"
              bsSize="large"
              isLoading={this.state.isDeleting}
              onClick={this.handleDelete}
              text="Delete"
              loadingText="Deleting..."
            />
          </form>
        )}
      </div>
    );
  }
}
