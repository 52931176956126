import React from "react";
import { Route, Redirect } from "react-router-dom";
import { AppliedRouteProps } from "./AppliedRoute";
import { RoutesEnum } from "../enums/RoutesEnum";

export default ({
  component: C,
  props: cProps,
  ...rest
}: AppliedRouteProps) => (
  <Route
    {...rest}
    render={props =>
      cProps.isAuthenticated ? (
        <C {...props} {...cProps} />
      ) : (
        <Redirect
          to={`${RoutesEnum.Login}?redirect=${props.location.pathname}${
            props.location.search
          }`}
        />
      )
    }
  />
);
