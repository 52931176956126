import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { Auth } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";

import styles from "./Login.module.scss";
import { RouterProps } from "react-router";
import { AppChildProps } from "../App";


interface LoginProps extends RouterProps, AppChildProps {}

interface LoginState {
  isLoading: boolean;
  email: string;
  password: string;
}

export default class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  validateForm(): boolean {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ email: event.target.value });

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ password: event.target.value });

  handleSubmit = async (event: React.FormEvent): Promise<void> => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
    } catch (e) {
      alert(e.message);
      this.setState({ isLoading: false });
    }
  };

  render(): JSX.Element {
    return (
      <div className={styles.Login}>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="email" bsSize="large">
            <ControlLabel>Email</ControlLabel>
            <FormControl
              autoFocus
              type="email"
              value={this.state.email}
              onChange={(
                e: React.ChangeEvent<FormControl & HTMLInputElement>
              ) => this.handleEmailChange(e)}
            />
          </FormGroup>
          <FormGroup controlId="password" bsSize="large">
            <ControlLabel>Password</ControlLabel>
            <FormControl
              value={this.state.password}
              onChange={(
                e: React.ChangeEvent<FormControl & HTMLInputElement>
              ) => this.handlePasswordChange(e)}
              type="password"
            />
          </FormGroup>
          <LoaderButton
            block
            bsSize="large"
            disabled={!this.validateForm()}
            type="submit"
            isLoading={this.state.isLoading}
            text="Login"
            loadingText="Logging in..."
          />
        </form>
      </div>
    );
  }
}
