import React, { Component } from "react";
import { FormGroup, FormControl, ControlLabel } from "react-bootstrap";
import { API } from "aws-amplify";

import LoaderButton from "../components/LoaderButton";

import { ApisEnum, ApiPathsEnum } from "../enums/ApiEnum";

import { s3Upload } from "../libs/awsLib";

import styles from "./NewItem.module.scss";

import { Item } from "../types/Item";

import config from "../config";
import { RouterProps } from "react-router";
import { AppChildProps } from "../App";


interface NewItemProps extends RouterProps, AppChildProps {};

interface NewItemState {
  isLoading: boolean;
  content: string;
  weight: number;
};

export default class NewItem extends Component<NewItemProps, NewItemState> {
  file: File | null;

  constructor(props: NewItemProps) {
    super(props);

    this.file = null;

    this.state = {
      isLoading: false,
      content: "",
      weight: 0
    };
  }

  validateForm(): boolean {
    return this.state.content.length > 0 && this.state.weight > 0;
  }

  createItem(item: Item): Promise<Item> {
    return API.post(ApisEnum.Items, ApiPathsEnum.Items, {
      body: item
    });
  }

  handleChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    this.setState({ ...this.state, [event.target.id]: event.target.value });

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.file = event.target.files!.length ? event.target.files![0] : null;
  }

  handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (this.file && this.file.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${config.MAX_ATTACHMENT_SIZE /
          1000000} MB.`
      );
      return;
    }

    this.setState({ isLoading: true });

    try {
      const attachment = this.file ? await s3Upload(this.file) : null;

      await this.createItem({
        attachment,
        content: this.state.content,
        weight: this.state.weight
      });
      this.props.history.push("/");
    } catch (e) {
      console.log(e);
      this.setState({ isLoading: false });
    }
  }

  render(): JSX.Element {
    return (
      <div className={styles.NewItem}>
        <form onSubmit={this.handleSubmit}>
          <FormGroup controlId="content">
            <FormControl
              onChange={(
                e: React.ChangeEvent<FormControl & HTMLInputElement>
              ) => this.handleChange(e)}
              value={this.state.content}
              componentClass="textarea"
            />
          </FormGroup>
          <FormGroup controlId="weight">
            <FormControl
              onChange={(
                e: React.ChangeEvent<FormControl & HTMLInputElement>
              ) => this.handleChange(e)}
              value={this.state.weight}
              componentClass="input"
            />
          </FormGroup>
          <FormGroup controlId="file">
            <ControlLabel>Attachment</ControlLabel>
            <FormControl
              onChange={(
                e: React.ChangeEvent<FormControl & HTMLInputElement>
              ) => this.handleFileChange(e)}
              type="file"
            />
          </FormGroup>
          <LoaderButton
            block
            bsStyle="primary"
            bsSize="large"
            disabled={!this.validateForm}
            type="submit"
            isLoading={this.state.isLoading}
            text="Create"
            loadingText="Creating..."
          />
        </form>
      </div>
    );
  }
}
